<template>
	<!-- <el-dialog :title="$t('i18nn_ff6fd823ffab7e6b')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShow" width="1000px"
		v-loading="loading_load" top="10px"> -->
	<el-drawer :wrapperClosable="false" :title="$t('i18nn_ff6fd823ffab7e6b')" append-to-body :visible.sync="dialogShow" :direction="'rtl'" size="1000px"v-loading="loading_load">
		<div style="margin-top: 10px;">
			<el-card>
				<div slot="header" class="">
					<h3>
						<i class="el-icon-position"></i>
						<span>{{$t('i18nn_daef27ebf42d4adb')}}</span>
					</h3>
				</div>
				<div>
					<el-checkbox :disabled="eHeadDisabled" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{$t('i18nn_3a7c1bda93906fe9')}}</el-checkbox>
					<div style="margin: 15px 0;"></div>
					<el-checkbox-group :disabled="eHeadDisabled" v-model="checkedItems" @change="handleCheckedItemsChange">
						<el-checkbox v-for="(item, index) in excelHead" :label="item.key" :key="index"
							style="margin-bottom: 10px;">{{ item.title }}</el-checkbox>
					</el-checkbox-group>
				</div>
			</el-card>
		</div>

		<div v-if="warningMsg" style="margin-top: 10px;">
			<el-alert :title="$t('i18nn_daaaeb1b7b22b126')" type="warning" :description="warningMsg" show-icon :closable="false"></el-alert>
		</div>
		
		<div style="margin-top: 10px;" v-if="!!excelOption.hasShowMerge">
			<el-card>
				<div slot="header" class="">
					<h3>
						<i class="el-icon-position"></i>
						<span>{{$t('i18nn_facd05c043886557')}}</span>
					</h3>
				</div>
				<div>
					<el-switch
					  v-model="hasMerge"
					  :active-text="$t('i18nn_6fd4956e676cec49')"
					  :inactive-text="$t('i18nn_1861b71eeabd88b9')"
						:active-value="true"
						:inactive-value="false"
						@change="changeMerge">
					</el-switch>
				</div>
			</el-card>
		</div>

		<div style="margin-top: 10px;" v-if="!!expHttpUrl">
			<el-card>
				<div slot="header" class="">
					<h3>
						<i class="el-icon-position"></i>
						<span><span>{{$t('i18nn_dd3438b8ee62f146')}}</span>（<span>{{$t('i18nn_055a45ea5fef0d11')}}</span>）</span>
					</h3>
				</div>

				<div v-if="allExpDataTotal">{{$t('i18nn_73bbf51487ec69e9')}}<strong>{{ allExpDataTotal }}</strong>{{$t('i18nn_3e48b039f79c39a0')}}</div>

				<div style="margin-top: 10px;">
					
					<div class="red" style="margin: 10px 0;"><span>{{$t('i18nn_8d8c79fcfaa246f5')}}</span>50000<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>，<span>{{$t('i18nn_21bae2bd339406af')}}</span>，<span>{{$t('i18nn_cc1f108900897a83')}}</span>...</div>
					<el-button type="primary" icon="el-icon-download" @click="exportExcelHttp(0, 50000)">
						<span>
							<span>{{$t('i18nn_83a93e396379a993')}}</span>(<span>{{$t('i18nn_5734a059a4622f20')}}</span>50000<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>)
						</span>
						<span v-if="'bill' == this.type">(<span>{{$t('i18nn_018de66d9d708dc7')}}</span>)</span>
					</el-button>
				</div>
			</el-card>
			
			<el-card style="margin-top: 10px;">
				<div slot="header" class="">
					<h3>
						<i class="el-icon-position"></i>
						<span><span>{{$t('i18nn_5d6f695ebedef0a4')}}</span>（<span>{{$t('i18nn_055a45ea5fef0d11')}}</span>）</span>
					</h3>
				</div>
				<div v-if="allExpDataTotal">{{$t('i18nn_73bbf51487ec69e9')}}<strong>{{ allExpDataTotal }}</strong>{{$t('i18nn_3e48b039f79c39a0')}}</div>
				<div class="red" style="margin-top: 10px;"><span>{{$t('i18nn_45605a8587b5efab')}}</span>；<span>{{$t('i18nn_f71701ed55a2e712')}}</span>{{pageSize}}<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>；</div>
				<div class="expBtnCon">
					<div class="expBtn">
						<el-button type="primary" icon="el-icon-download" @click="exportExcelHttp(0, pageSize)">
							<span>
								<span>{{$t('i18nn_8d8c79fcfaa246f5')}}</span>{{pageSize}}<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>
							</span>
							<span v-if="'bill' == type">(<span>{{$t('i18nn_018de66d9d708dc7')}}</span>)</span>
						</el-button>
					</div>
					<div class="expBtn" v-for="(item,index) in batchNumList" :key="index">
						<el-button type="primary" icon="el-icon-download" @click="exportExcelHttp(item.start, pageSize)" v-if="allExpDataTotal > item.start">
							<span>{{$t('i18nn_be331b9c430a360b')}}</span>{{item.start}}-{{item.end}}<span>{{$t('i18nn_117aa3f27e5e430c')}}</span></el-button>
						<span v-if="'bill' == type">(<span>{{$t('i18nn_018de66d9d708dc7')}}</span>)</span>
					</div>
					<!-- <div class="expBtn" v-if="allExpDataTotal > 10000">
						<el-button type="primary" icon="el-icon-download" @click="exportExcelHttp(10000, 15000)">
							导出第10000-15000条</el-button>
					</div>
					<div class="expBtn" v-if="allExpDataTotal > 15000">
						<el-button type="primary" icon="el-icon-download" @click="exportExcelHttp(15000, 20000)">
							导出第15000-20000条</el-button>
					</div>
					<div class="expBtn" v-if="allExpDataTotal > 20000">
						<el-button type="primary" icon="el-icon-download" @click="exportExcelHttp(20000, 25000)">
							导出第20000-25000条</el-button>
					</div>
					<div class="expBtn" v-if="allExpDataTotal > 25000">
						<el-button type="primary" icon="el-icon-download" @click="exportExcelHttp(25000, 30000)">
							导出第25000-30000条</el-button>
					</div>
					<div class="expBtn" v-if="allExpDataTotal > 30000">
						<el-button type="primary" icon="el-icon-download" @click="exportExcelHttp(30000, 35000)">
							导出第30000-35000条</el-button>
					</div>
					<div class="expBtn" v-if="allExpDataTotal > 35000">
						<el-button type="primary" icon="el-icon-download" @click="exportExcelHttp(35000, 40000)">
							导出第35000-40000条</el-button>
					</div>
					<div class="expBtn" v-if="allExpDataTotal > 40000">
						<el-button type="primary" icon="el-icon-download" @click="exportExcelHttp(40000, 45000)">
							导出第40000-45000条</el-button>
					</div>
					<div class="expBtn" v-if="allExpDataTotal > 45000">
						<el-button type="primary" icon="el-icon-download" @click="exportExcelHttp(45000, 50000)">
							导出第45000-50000条</el-button>
					</div> -->
				</div>
				
			</el-card>
		</div>

		<div style="margin-top: 10px;" v-if="!noShowExpDefExcel">
			<el-card>
				<div slot="header" class="">
					<h3>
						<i class="el-icon-position"></i>
						<span><span>{{$t('i18nn_0d9a272b3124998c')}}</span>（<span>{{$t('i18nn_055a45ea5fef0d11')}}</span>）</span>
					</h3>
				</div>
				<div>
					<el-button type="primary" icon="el-icon-download" @click="exportExcel">{{$t('i18nn_ff6fd823ffab7e6b')}}<span v-if="'bill' == this.type">(含合计)</span>
					</el-button>
					<span><span>{{$t('i18nn_c936d3b74e41b901')}}</span>，<span>{{$t('i18nn_73bbf51487ec69e9')}}</span>{{ excelData.length }}<span>{{$t('i18nn_117aa3f27e5e430c')}}</span></span>
				</div>
			</el-card>
		</div>

		<!-- <div slot="footer" class="dialog-footer"> -->
			<!-- <el-button type="primary" icon="el-icon-download" @click="exportExcel">{{$t('i18nn_c432271c995f31a5')}}</el-button> -->
			<!-- <el-button type="primary" plain @click="dialogShow = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button> -->
		<!-- </div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	// const cityOptions = [this.$t('i18nn_5fc8144f38fd1ebc'), this.$t('i18nn_9c0ca340eff4fdce'), this.$t('i18nn_cb2ec494b0a4d52c'), this.$t('i18nn_b9a4aed64afd0cf9')];

	import excelUtilsNew from '@/utils/excelUtilsNew.js';

	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		props: {
			// mobile:"",
			// isShow: {
			//   default: function() {
			//     return false;
			//   },
			//   type: Boolean
			// },
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			excelHead: {
				default: function() {
					return [];
				},
				type: Array
			},
			pagination: {
				default: function() {
					return {};
				},
				type: Object
			},
			excelData: {
				default: function() {
					return [];
				},
				type: Array
			},
			noShowExpDefExcel: {
				default: function() {
					return false;
				},
				type: Boolean
			},

			excelName: {
				default: function() {
					return '';
				},
				type: String
			},
			excelOption: {
				default: function() {
					return {};
				},
				type: Object
			},
			expHttpUrl: {
				default: function() {
					return '';
				},
				type: String
			},
			expHttpFilter: {
				default: function() {
					return {};
				},
				type: Object
			},
			warningMsg: {
				default: function() {
					return '';
				},
				type: String
			},
			hasFormatter: {
				default: function() {
					return false;
				},
				type: Boolean
			},
			formatterData: {
				type: Function
			},
			//类型--默认列表，
			// bill-->账单合计-第二个页签放合计的数据
			type: {
				default: function() {
					return '';
				},
				type: String
			},
			// sheet2StaticData: {
			// 	default: function() {
			// 		return {
			// 			columns: {},
			// 			Data: {}
			// 		};
			// 	},
			// 	type: Object
			// },
			sheet2StaticData: {
				default: function() {
					return [];
				},
				type: Array
			},
			// hasMerge: {
			// 	default: function() {
			// 		return false;
			// 	},
			// 	type: Boolean
			// },
			// mergeData: {
			// 	default: function() {
			// 		return [];
			// 	},
			// 	type: Array
			// },
			// fileList: {
			//   default: function() {
			//     return [];
			//   },
			//   type: Array
			// }
		},
		components: {},
		data() {
			return {
				dialogShow: false,
				// loadingUpload: false,

				// tableData: [],
				

				checkAll: false,
				checkedItems: [],
				// cities: cityOptions,
				isIndeterminate: true,

				loading_load: false,
				allExpData: [],
				allExpDataTotal: 0,
				
				hasMerge: false,
				mergeDataRow:[],
				mergeDataRow2:[],
				eHeadDisabled: false,
				
				pageSize:2000,
				batchNumList:[],
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogShow = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			this.initData();
		},
		methods: {
			initData() {
				//初始化
				this.checkedItems = this.excelHead.map(item => {
					return item.key;
				});
				this.checkAll = true;
				this.isIndeterminate = false;
				//合计显示的数字
				this.allExpDataTotal = this.pagination.total;
				//计算分页,不计算第一个分页
				this.calcPage();
				//初始化合并
				this.hasMerge = !!this.excelOption && !!this.excelOption.hasShowMerge;
				this.mergeDataRow = [];
				this.mergeDataRow2 = [];
				if(this.hasMerge){
					this.eHeadDisabled = true;
				}
			},
			
			handleCheckAllChange(val) {
				console.log(val);
				// return;
				// this.checkedItems = val ? this.excelHead : [];
				if (val) {
					this.checkedItems = this.excelHead.map(item => {
						return item.key;
					});
				} else {
					this.checkedItems = [];
				}
				this.isIndeterminate = false;
			},
			handleCheckedItemsChange(value) {
				console.log(value);
				// return;
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.excelHead.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.excelHead.length;
			},
			changeMerge(value){
				if(value){
					this.eHeadDisabled = true;
					this.handleCheckAllChange(true)
				} else {
					this.eHeadDisabled = false;
				}
			},
			exportExcel() {
				let selHead = [];
				this.excelHead.map(item => {
					if (
						this.checkedItems.findIndex(item2 => {
							return item2 == item.key;
						}) > -1
					) {
						selHead.push(item);
					}
				});
				console.log('selHead', selHead);
				// excelUtilsNew.exportExcel(selHead, this.excelData, this.excelName, this.excelOption);
				let excelSheetData = [{
					sheetName: 'sheet1',
					columns: selHead,
					Data: this.excelData
				}];
				if ('bill' == this.type) {
					excelSheetData = [{
							sheetName: 'sheet1',
							columns: selHead,
							Data: this.excelData,
							lastData: this.sheet2StaticData
						},
						// {
						// 	sheetName: this.sheet2StaticData.sheetName,
						// 	columns: this.sheet2StaticData.columns,
						// 	Data: this.sheet2StaticData.Data
						// }
					];
				}
				excelUtilsNew.exportExcelForMultiSheet(excelSheetData, this.excelName, this.excelOption,{});

				this.dialogShow = false;
			},
			exportExcelHttp(offset, limit) {
				//请求分页数据
				// getPageData() {
				this.loading_load = true;

				let filterData = Object.assign({
						offset: offset, //起始条数
						limit: limit //请求的条数
					},
					this.expHttpFilter
				);

				this.$http
					.put(this.expHttpUrl, filterData)
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);
						this.loading_load = false;
						if (200 == data.code) {
							if (this.hasFormatter) {
								this.allExpData = this.formatterData(data.rows);
								if(this.hasMerge && data.rows){//需要合并
									if(this.excelOption.mergeLengthKey && this.excelOption.mergeLengthKey2){//合并第一层和第二层
										let mergeDataRow = [];
										let mergeDataRow2 = [];
										data.rows.forEach(item=>{
											// if(item[this.excelOption.mergeLengthKey]){
											// 	mergeDataRow.push(item[this.excelOption.mergeLengthKey].length);
											// } else {
											// 	return mergeDataRow.push(1);
											// }
											
											if(item[this.excelOption.mergeLengthKey]){
												let lv2Num = 0;
												// let lv1Num = 0;
												item[this.excelOption.mergeLengthKey].forEach(item2=>{
													if(item2[this.excelOption.mergeLengthKey2]){
														lv2Num = lv2Num+item2[this.excelOption.mergeLengthKey2].length;
														mergeDataRow2.push(item2[this.excelOption.mergeLengthKey2].length);
													} else {
														lv2Num = lv2Num+1;
														mergeDataRow2.push(1);
													}
												});
												mergeDataRow.push(lv2Num);
											} else {
												// lv2Num = 1;
												// mergeDataRow.push(1);
											}
											
											// mergeDataRow2.push(item[this.excelOption.mergeLengthKey].length);
										})
										this.mergeDataRow = mergeDataRow;
										this.mergeDataRow2 = mergeDataRow2;
									} else if(this.excelOption.mergeLengthKey){//仅合并一层
										this.mergeDataRow = data.rows.map(item=>{
											if(item[this.excelOption.mergeLengthKey]){
												return item[this.excelOption.mergeLengthKey].length;
											} else {
												return 1;
											}
										});
									}
									
								}
							} else {
								this.allExpData = data.rows;
							}
							
							
							

							this.allExpDataTotal = parseInt(data.total);
							
							// let batchNumList = [];
							// let pageSize = this.pageSize;
							
							// //计算分页,不计算第一个分页
							this.calcPage();
							// if(this.allExpDataTotal<pageSize){
							// 	// batchNumList.push({
							// 	// 	start:0,
							// 	// 	end:this.allExpDataTotal
							// 	// });
							// } else {
							// 	//计算一共有多少分页
							//  let batchPage =	Math.ceil(this.allExpDataTota / pagesize);
							//  for(let i=1;i<batchPage;i++){
							// 	 let end = (i+1)*pagesize;
							// 	 if(end>this.allExpDataTota){
							// 		 end = this.allExpDataTota;
							// 	 }
							// 	 batchNumList.push({
							// 	 	start:i*pagesize,
							// 	 	end:end
							// 	 });
							//  }
							// }
							// console.log('batchNumList',batchNumList);
							// this.batchNumList = batchNumList;
							// return;
							this.exportExcel2();
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error('列表数据，请求失败！');
						this.loading_load = false;
					});
				// },
			},
			//计算分页的起始和结束，不计算第一个分页
			calcPage(){
				let batchNumList = [];
				let pageSize = this.pageSize;
				
				//计算分页,不计算第一个分页
				if(this.allExpDataTotal<pageSize){
					// batchNumList.push({
					// 	start:0,
					// 	end:this.allExpDataTotal
					// });
				} else {
					//计算一共有多少分页
				 let batchPage =	Math.ceil(this.allExpDataTotal / pageSize);
				 console.log('batchPage',batchPage);
				 for(let i=1;i<batchPage;i++){
					 let end = (i+1)*pageSize;
					 if(end>this.allExpDataTotal){
						 end = this.allExpDataTotal;
					 }
					 batchNumList.push({
					 	start:i*pageSize,
					 	end:end
					 });
				 }
				}
				console.log('batchNumList',batchNumList);
				this.batchNumList = batchNumList;
			},
			exportExcel2() {
				this.loading_load = true;
				let selHead = [];
				this.excelHead.map(item => {
					if (
						this.checkedItems.findIndex(item2 => {
							return item2 == item.key;
						}) > -1 &&
						'image' !== item.types
					) {
						selHead.push(item);
					}
				});
				console.log('selHead', selHead);
				// excelUtilsNew.exportExcel(selHead, this.allExpData, this.excelName, this.excelOption);
				// excelUtilsNew.exportExcelForMultiSheet([{
				// 	sheetName: "sheet1",
				// 	columns: selHead,
				// 	Data: this.excelData
				// }],this.excelName, this.excelOption);
				let excelSheetData = [{
					sheetName: 'sheet1',
					columns: selHead,
					Data: this.allExpData
				}];
				if ('bill' == this.type) {
					excelSheetData = [{
							sheetName: 'sheet1',
							columns: selHead,
							Data: this.allExpData,
							lastData: this.sheet2StaticData
						},
						// {
						// 	sheetName: this.sheet2StaticData.sheetName,
						// 	columns: this.sheet2StaticData.columns,
						// 	Data: this.sheet2StaticData.Data
						// }
					];
				}
				excelUtilsNew.exportExcelForMultiSheet(excelSheetData, this.excelName, this.excelOption,{
					hasMerge:this.hasMerge,
					mergeDataRow:this.mergeDataRow,
					mergeDataRow2:this.mergeDataRow2,
				});
				// this.dialogShow = false;
				this.loading_load = false;
			}
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.expBtnCon {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;
		.expBtn {
			margin-right: 10px;
		}
	}
</style>
